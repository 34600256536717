import { Button, ButtonProps } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FiCamera } from "react-icons/fi";
import Quagga from "@ericblade/quagga2";

interface BarcodeScannerProps extends ButtonProps {
  setValue: (value: string) => void;
}

export const BarcodeScanner = ({ setValue, ...props }: BarcodeScannerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      Quagga.init({
        inputStream: {
          type: 'LiveStream',
          target: document.querySelector('#scanner-container') as any,
          constraints: {
            facingMode: 'environment', // ou 'user' para a câmera frontal
          },
        },
        decoder: {
          readers: ['ean_reader', 'code_128_reader'], // Especifica o leitor para EAN-8
        },
      }, (err) => {
        if (err) {
          console.error(err);
          return;
        }
        Quagga.start();
      });

      Quagga.onDetected(function (result) {
        const code = result.codeResult.code;

        if ((code ?? '').length > 3) {
          setValue(code ?? '');
          setIsOpen(false);
          (document.getElementById('scanner-container') as any).innerHTML = '';
        }
      });
    }

    return () => {
      Quagga.stop();
    };
  }, [isOpen]);

  return (
    <React.Fragment>
      <Button className="flex gap-2 items-center" {...props} onClick={() => setIsOpen(true)}>
        <FiCamera /> Ler código de barras
      </Button>

      <div id="scanner-container" />
    </React.Fragment>
  );
}