import { useState } from "react"
import { DefaultButton } from "../../sistema/components/DefaultButton"
import { DialogModal } from "../../sistema/components/DialogModal"
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary"
import { FinanceiroParcela } from "../types/financeiro_parcela"
import { Input, Modal, ModalContent, ModalBody, ModalCloseButton, ModalHeader, ModalOverlay } from "@chakra-ui/react"

export const CalculadoraJuros = ({ financeiroParcela, valorOriginal, onClose }: { financeiroParcela: FinanceiroParcela | null, valorOriginal: number, onClose: () => void }) => {
  const [taxaJuros, setTaxaJuros] = useState(5);
  const [diasAtraso, setDiasAtraso] = useState(financeiroParcela?.dias_atraso ?? 0);

  return (
    <Modal
      isOpen={!!financeiroParcela}
      onClose={onClose}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ListHeaderSecondary>
            Calculadora de juros
            <DefaultButton size="xs" colorScheme="green" ml={2} onClick={onClose}>
              Voltar
            </DefaultButton>
          </ListHeaderSecondary>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="w-full">
            <div className="w-full p-3 mb-4 bg-gray-50 rounded-lg shadow-sm">
              <div className="flex items-center mb-3">
                <div className="text-sm font-semibold text-gray-700">Calculadora de Juros Compostos</div>
              </div>



              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                  <div className="text-sm text-gray-600 mb-1">Valor do pagamento</div>
                  <div className="text-lg font-medium text-gray-800">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(valorOriginal)}
                  </div>
                </div>

                <div>
                  <div className="text-sm text-gray-600 mb-1">Taxa de Juros (%)</div>
                  <Input bg={'white'} type="number" value={taxaJuros} onChange={(e) => setTaxaJuros(parseFloat(e.target.value))} />
                </div>

                <div>
                  <div className="text-sm text-gray-600 mb-1">Dias em Atraso</div>
                  <div className="text-lg font-medium text-gray-800">
                    {diasAtraso || 0} dias
                  </div>
                </div>

                <div>
                  <div className="text-sm text-gray-600 mb-1">Juros Calculado</div>
                  <div className="text-lg font-medium text-blue-600">
                    {(() => {
                      const jurosCalculado = valorOriginal * Math.pow(1 + taxaJuros / 100, diasAtraso / 30) - valorOriginal;

                      return new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(!taxaJuros ? 0 : jurosCalculado);
                    })()}
                  </div>
                </div>
              </div>

              <div className="mt-3 text-xs text-gray-500">
                * Cálculo baseado em juros compostos mensais
              </div>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>

  )
}