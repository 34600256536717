import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { apiCall } from "../utils/apiCall";
import { formatError } from "../utils/formatError";
import { financeiroActions } from "./reducer";
import { Financeiro } from "./types/financeiro";
import { novoFinanceiro } from "./data/financeiro";
import { IFiltrosRelatorioFinanceiro } from "./views/FinanceiroList";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { FinanceiroParcela } from "./types/financeiro_parcela";
import { IFiltrosRelatorioFinanceiroBaixas } from "./views/FinanceiroBaixas";
import { FinanceiroParcelaQuitacao } from "./types/financeiro_quitacao";

function* saveFinanceiroRequestWorker({ payload }: PayloadAction<Financeiro>) {
  try {
    const res: AxiosResponse<Financeiro> = yield call(apiCall, {
      url: `financeiros?include=parcelas,parcelas.quitacoes&append=camposPersonalizados`,
      method: "post",
      data: payload,
    });
    yield put(financeiroActions.saveFinanceiroRequestSuccess(res.data));
  } catch (error: any) {
    yield put(financeiroActions.error(formatError(error)));
  }
}
function* deleteFinanceiroRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `financeiros/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(financeiroActions.deleteFinanceiroRequestSuccess());
  } catch (error: any) {
    yield put(financeiroActions.error(formatError(error)));
  }
}

function* financeiroRequestWorker({
  payload,
}: PayloadAction<{ id: string; tipo: "pagar" | "receber" }>) {
  try {
    if (payload.id === "novo") {
      yield put(
        financeiroActions.financeiroRequestSuccess(novoFinanceiro(payload.tipo))
      );
      return;
    }

    const res: AxiosResponse<Financeiro> = yield call(apiCall, {
      url: `financeiros/${payload.id}?include=parcelas,parcelas.quitacoes&append=camposPersonalizados`,
      method: "get",
    });
    yield put(financeiroActions.financeiroRequestSuccess(res.data));
  } catch (error: any) {
    yield put(financeiroActions.error(formatError(error)));
  }
}

export type IRelacaoFinanceiro = {
  totais: {
    valor_total: string;
    total_aberto: string;
    total_comissao: string;
    total_quitado: string;
    total_bruto: string;
    total_descontos: string;
    total_juros: string;
    total_liquido: string;
  };
  itens: ApiPaginationRes<FinanceiroParcela[]>;
};

function* buscaRelatorioFinanceiroRequestWorker({
  payload,
}: PayloadAction<IFiltrosRelatorioFinanceiro>) {
  try {
    const res: AxiosResponse<IRelacaoFinanceiro> =
      yield call(apiCall, {
        url: `relacao-financeiros?filtros=${JSON.stringify({
          ...payload,
        })}&page=${payload.page}`,
        method: "get",
      });
    yield put(financeiroActions.buscaRelatorioFinanceiroRequestSuccess(res.data));
  } catch (error: any) {
    yield put(financeiroActions.error(formatError(error)));
  }
}

function* buscaRelatorioFinanceiroBaixasRequestWorker({
  payload,
}: PayloadAction<IFiltrosRelatorioFinanceiroBaixas>) {
  try {
    const res: AxiosResponse<FinanceiroParcelaQuitacao[]> =
      yield call(apiCall, {
        url: `relacao-financeiros-baixas?filtros=${JSON.stringify({
          ...payload,
        })}`,
        method: "get",
      });
    yield put(
      financeiroActions.buscaRelatorioFinanceiroBaixasRequestSuccess(
        res.data
      )
    );
  } catch (error: any) {
    yield put(financeiroActions.error(formatError(error)));
  }
}

export function* financeiroSaga() {
  yield all([
    takeLatest("financeiro/saveFinanceiroRequest", saveFinanceiroRequestWorker),
    takeLatest(
      "financeiro/deleteFinanceiroRequest",
      deleteFinanceiroRequestWorker
    ),
    takeLatest("financeiro/financeiroRequest", financeiroRequestWorker),
    takeLatest("financeiro/buscaRelatorioFinanceiroRequest", buscaRelatorioFinanceiroRequestWorker),
    takeLatest(
      "financeiro/buscaRelatorioFinanceiroBaixasRequest",
      buscaRelatorioFinanceiroBaixasRequestWorker
    ),
  ]);
}
