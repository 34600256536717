import React, { useEffect, useState } from "react";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { FiPlus } from "react-icons/fi";
import { DialogModal } from "../../sistema/components/DialogModal";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { Form, Formik } from "formik";
import { Stock, StockType } from "../types/stock";
import { newStock } from "../data/stock";
import { pdvActions } from "../../pdv/reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import InputSelect from "../../sistema/components/InputSelect";
import { ProdutoBobjeto } from "./ProdutoBobjeto";
import InputNumber from "../../sistema/components/InputNumber";
import { getDecimalPlacesOfSystem } from "../../sistema/utils/getDecimalPlacesOfSystem";
import InputField from "../../sistema/components/InputField";
import { Badge } from "@chakra-ui/react";
import { entradaesaidaActions } from "../recucer";

type IncludeStockProps = {
  produto_id?: string | number;
  stock_location_id?: string | number;
};

export const IncludeStock = ({
  produto_id = "",
  stock_location_id = "",
}: IncludeStockProps) => {
  const isMobile = useSelector((state: RootState) => state.sistema?.isLoading);

  const locationsStock = useSelector(
    (state: RootState) => state.pdv?.locationsStock ?? []
  );

  const success = useSelector(
    (state: RootState) => state.entradaesaida?.success
  );

  const [currentItem, setCurrentItem] = useState<Stock | null>(null);

  const onOpenModal = () => {
    const firstStockLocation =
      locationsStock.length > 0 ? locationsStock[0].id ?? "" : "";

    setCurrentItem({
      ...newStock(),
      produto_id,
      stock_location_id: stock_location_id
        ? stock_location_id
        : firstStockLocation,
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!success) {
      setCurrentItem(null);
    }
  }, [success]);

  return (
    <React.Fragment>
      <DefaultButton className="flex gap-1 items-center" onClick={onOpenModal}>
        <FiPlus /> Nova movimentação no estoque
      </DefaultButton>

      <DialogModal
        show={currentItem != null}
        close={() => setCurrentItem(null)}
        maxWidth="xl"
        title={
          <ListHeaderSecondary>
            Nova movimentação no estoque
            <DefaultButton
              type="button"
              onClick={() => setCurrentItem(null)}
              size={"sm"}
              ml={1}
            >
              Voltar
            </DefaultButton>
          </ListHeaderSecondary>
        }
        content={
          <div className="w-full min-h-[50vh]">
            {currentItem && (
              <Formik
                enableReinitialize
                initialValues={currentItem}
                onSubmit={(values, { setErrors }) => {
                  if (values.quantity <= 0) {
                    setErrors({
                      quantity: "Tem que ser maior que zero!",
                    });
                    return;
                  }

                  if (!values.stock_location_id) {
                    setErrors({
                      stock_location_id: "Tem que ser selecionado!",
                    });
                    return;
                  }

                  if (!values.produto_id) {
                    setErrors({
                      produto_id: "Tem que ser selecionado!",
                    });
                    return;
                  }

                  /*if (!values.description) {
                    setErrors({
                      description: "Tem que ser preenchido!",
                    });
                    return;
                  }*/

                  dispatch(
                    entradaesaidaActions.saveStockMovimentationRequest(values)
                  );
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form style={{ width: "100%" }}>
                    <ProdutoBobjeto
                      label="Produto"
                      name="produto_id"
                      width="full"
                      onSelectItemOnLoad={(product) =>
                        setFieldValue("product", product)
                      }
                      onSelectItem={(product) =>
                        setFieldValue("product", product)
                      }
                    />
                    {values?.product?.controls_stock == 1 && (
                      <div className="w-full flex flex-wrap">
                        <InputSelect
                          label="Local do estoque"
                          name="stock_location_id"
                          width={"100%"}
                          noPlaceholer
                        >
                          {locationsStock.map((location) => (
                            <option key={`${location.id}`} value={location.id}>
                              {location.name}
                            </option>
                          ))}
                        </InputSelect>

                        <InputNumber
                          decimalPlaces={getDecimalPlacesOfSystem()}
                          label="Quantidade"
                          name="quantity"
                          width={isMobile ? "100%" : "50%"}
                        />

                        <InputSelect
                          label="Tipo"
                          name="type"
                          width={isMobile ? "100%" : "50%"}
                          noPlaceholer
                        >
                          <option value={StockType.Input}>Entrada</option>
                          <option value={StockType.Output}>Saída</option>
                        </InputSelect>

                        <InputField
                          textarea
                          rows={3}
                          name="description"
                          maxLength={255}
                          label="Descrição"
                        />
                        <DefaultButton type="submit" mt={1} width={"full"}>
                          Salvar movimento de estoque
                        </DefaultButton>
                      </div>
                    )}

                    {values?.product?.controls_stock == 0 && (
                      <div className="w-full flex justify-center">
                        <Badge colorScheme="red" className="mx-auto">
                          Esse produto não está configurado para controlar
                          estoque no cadastro.
                        </Badge>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            )}
          </div>
        }
        footer={
          <div className="w-full">
            <DefaultButton
              type="button"
              onClick={() => setCurrentItem(null)}
              size={"sm"}
            >
              Voltar
            </DefaultButton>
          </div>
        }
      />
    </React.Fragment>
  );
};
