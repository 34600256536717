import { useSelector } from "react-redux";
import { Section } from "../../../sistema/components/Section";
import { RootState } from "../../../app/mainReducer";
import { formatData } from "../../../utils/data";
import { formatValor } from "../../../utils/formatValor";
import { getDecimalPlacesOfSystem } from "../../../sistema/utils/getDecimalPlacesOfSystem";
import { CashRegisterTransactionType } from "../../types/cash_register_transaction";
import { EditObservations } from "./EditObservations";

export const Side = () => {
  const selectedCashRegister = useSelector((state: RootState) => state.cashRegister?.cashRegister);

  return (
    <div className="w-full relative border">
      <Section>
        <div className="text-gray-700 font-bold text-xl text-center pt-2">
          SALDO INICIAL
        </div>
        <div className="font-semibold text-orange-500 text-center text-lg pb-2">
          R$ {formatValor(selectedCashRegister?.initial_value ?? 0, getDecimalPlacesOfSystem())}
        </div>
      </Section>
      <div className="w-full my-1"></div>
      <Section>
        <div className="text-gray-700 font-bold text-xl text-center pt-2">
          TOTAL ENTRADAS
        </div>
        <div className="font-semibold text-green-600 text-center text-lg pb-2">
          R$ {formatValor((selectedCashRegister?.transactions ?? []).filter(i => i.type == CashRegisterTransactionType.Input).reduce((a, b) => a + parseFloat(b.value as any), 0))}
        </div>
      </Section>
      <div className="w-full my-1"></div>
      <Section>
        <div className="text-gray-700 font-bold text-xl text-center pt-2">
          TOTAL SAÍDAS
        </div>
        <div className="font-semibold text-red-600 text-center text-lg pb-2">
          R$ {formatValor((selectedCashRegister?.transactions ?? []).filter(i => i.type == CashRegisterTransactionType.Output).reduce((a, b) => a + parseFloat(b.value as any), 0))}
        </div>
      </Section>
      <div className="w-full my-1"></div>
      <Section>
        <div className="text-gray-700 font-bold text-xl text-center pt-2">
          SALDO FINAL
        </div>
        <div className="font-semibold text-orange-500 text-center text-lg pb-2">
          R$ {formatValor(selectedCashRegister?.final_value ?? 0, getDecimalPlacesOfSystem())}
        </div>
      </Section>
      <div className="w-full my-1"></div>
      <Section>
        <div className="flex gap-1 justify-center items-center text-gray-700 font-bold text-xl text-center pt-2">
          OBSERVAÇÕES {selectedCashRegister && !selectedCashRegister?.finished_at && <EditObservations />}
        </div>
        <div className="font-semibold text-gray-600 text-center text-lg pb-2 ">
          {selectedCashRegister?.observations ? selectedCashRegister?.observations : 'N/D'}
        </div>
      </Section>
      <div className="w-full my-1"></div>
      <Section>
        <div className="text-gray-700 font-bold text-xl text-center pt-2">
          DATA ABERTURA
        </div>
        <div className="font-semibold text-gray-600 text-center text-lg pb-2">
          {selectedCashRegister?.created_at ? formatData(selectedCashRegister?.created_at, 'dd/MM/yyyy HH:mm') : 'N/D'}
        </div>
      </Section>
      <div className="w-full my-1"></div>
      <Section>
        <div className="text-gray-700 font-bold text-xl text-center pt-2">
          DATA FECHAMENTO
        </div>
        <div className="font-semibold text-gray-600 text-center text-lg pb-2 ">
          {selectedCashRegister?.finished_at ? formatData(selectedCashRegister?.finished_at, 'dd/MM/yyyy HH:mm') : 'N/D'}
        </div>
      </Section>
    </div>
  )
}