export const TesteCompartilhar = () => {
  const shareLink = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Compartilhe este Link!',
        text: 'Olha esse link legal que encontrei!',
        url: 'https://www.mercadolivre.com.br',
      })
        .then(() => console.log('Link compartilhado com sucesso!'))
        .catch((error) => console.error('Erro ao compartilhar', error));
    } else {
      alert('Compartilhamento não é suportado neste navegador.');
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Teste de Compartilhamento</h1>
      <p>Clique no botão abaixo para compartilhar um link.</p>
      <button
        onClick={shareLink}
        style={{
          backgroundColor: '#4CAF50',
          color: 'white',
          fontSize: '16px',
          padding: '10px 20px',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '5px',
        }}

      >
        Compartilhar Link
      </button>
    </div>
  );
}